import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { App } from "antd";
import { useNavigate } from "@tanstack/react-router";
import { Config } from "src/routes/dashboard/types";
import { useAppStore } from "src/routes/dashboard/store";
import { isUserLoggedIn, logoutUser } from "src/utils/user";

export function usePrivateConfig() {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const isAuthenticated = isUserLoggedIn();

  return useQuery({
    queryKey: ["privateConfig"],
    queryFn: async () => {
      const url = `/config/private`;
      const response = await axios.get<Config>(url);

      if (!response.data) {
        notification.error({ message: "Something went wrong!" });
        logoutUser();
        void navigate({ to: "/login" });
        return null;
      }

      useAppStore.getState().setConfig(response.data ?? null);
      void navigate({
        to: response.data?.products?.places
          ? "/dashboard/places/map-view"
          : response.data?.products?.geopersona
            ? "/dashboard/geopersona/map-view"
            : "/dashboard/$404",
      });

      return response.data;
    },
    retry: false,
    enabled: isAuthenticated,
  });
}
