import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Spinner } from "src/components/Spinner.tsx";
import { usePrivateConfig } from "src/queries/privateConfigQuery";

export const Route = createFileRoute("/dashboard")({
  component: Dashboard,
});

function Dashboard() {
  const { isPending } = usePrivateConfig();

  if (isPending) return <Spinner styles={{ minHeight: "100vh" }} />;

  return <Outlet />;
}
